<template>
  <el-dialog
    v-model="showMessageBoxDialog"
    width="30%"
    :show-close="false"
    align-center
    :close-on-press-escape="false"
    :close-on-click-modal="false"
    class="elv-confirm-info-dialog"
  >
    <template #header="{ titleId }">
      <h4 :id="titleId" class="elv-confirm-info-header__title">{{ props.title }}</h4>
      <SvgIcon
        v-if="props.showCloseButton"
        name="group-close"
        width="24"
        height="24"
        class="elv-confirm-info-header__close"
        @click="onCheckMessageBoxDialog"
      ></SvgIcon>
    </template>
    <slot name="content"></slot>
    <template #footer>
      <span class="dialog-footer">
        <el-button
          v-if="props.showCancelButton"
          type="info"
          round
          class="elv-confirm-info-footer__cancel"
          @click="onCancel"
          >{{ props.cancelButtonText }}</el-button
        >
        <el-button
          type="primary"
          round
          class="elv-confirm-info-footer__confirm"
          :loading="props.loading"
          @click="onConfirm"
          >{{ props.confirmButtonText }}</el-button
        >
      </span>
    </template>
  </el-dialog>
</template>

<script setup lang="ts">
import { $t } from '@/i18n'

const props = defineProps({
  showCancelButton: {
    type: Boolean,
    default: true
  },
  showCloseButton: {
    type: Boolean,
    default: false
  },
  cancelButtonText: {
    type: String,
    default: $t('button.cancel')
  },
  confirmButtonText: {
    type: String,
    default: $t('button.confirm')
  },
  loading: {
    type: Boolean,
    default: false
  },
  title: {
    type: String,
    required: true
  }
})

const showMessageBoxDialog = ref(false)

const emit = defineEmits(['onConfirmEvent', 'onCancelEvent'])

// 切换弹窗展示
const onCheckMessageBoxDialog = () => {
  showMessageBoxDialog.value = !showMessageBoxDialog.value
}

const onConfirm = () => {
  emit('onConfirmEvent')
}

const onCancel = () => {
  emit('onCancelEvent')
}

defineExpose({ onCheckMessageBoxDialog })
</script>

<style lang="scss">
.elv-confirm-info-dialog {
  width: 480px;
  height: auto;
  box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.05), 0px 0px 1px rgba(0, 0, 0, 0.3);
  border-radius: 6px;

  .el-dialog__body {
    font-size: 14px;
    color: #1e2024;
    line-height: 20px;
    font-family: 'Plus Jakarta Sans';
    font-weight: 500;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 30px 45px;
  }

  .el-dialog__header {
    text-align: center;
    font-size: 16px;
    color: #0e0f11;
    line-height: 24px;
    // height: 49px;
    font-family: 'Plus Jakarta Sans';
    font-weight: 800;

    margin: 0;
    padding: 0;
    display: flex;
    position: relative;
    align-items: flex-end;
    justify-content: center;
    margin-top: 26px;

    .elv-confirm-info-header__title {
      font-family: 'Plus Jakarta Sans';
      font-weight: 800;
      font-size: 16px;
      line-height: 24px;
      display: flex;
      align-items: center;
      word-break: break-word;
      color: #0e0f11;
      margin: 0;
    }

    .elv-confirm-info-header__close {
      position: absolute;
      right: 26px;
      bottom: 3px;
      cursor: pointer;
    }
  }

  .elv-confirm-content-info__title {
    font-family: 'Plus Jakarta Sans';
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    display: flex;
    align-items: center;
    text-align: center;
    color: #1e2024;
    word-break: break-word;
  }

  .el-dialog__footer {
    text-align: center;
    padding-top: 0px;

    .el-button {
      padding: 14px 35px;
      height: 44px;
      width: 117px;
      background: #1753eb;
      border-radius: 22px;
      font-family: 'Plus Jakarta Sans';
      font-weight: 500;
      font-size: 13px;
      line-height: 16px;
      color: #fff;
      border: 0px;
    }

    .elv-confirm-info-footer__cancel {
      background: #edf0f3;
      color: #636b75;
      margin-right: 20px;
    }

    // .elv-confirm-info-footer__confirm {
    // }
  }
}
</style>
